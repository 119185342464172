<template>
    <div class="single-select">
        <multiselect
            aria-readonly="false"
            label="text"
            track-by="text"
            openDirection="below"
            v-model="optionSelected"
            :id="id"
            :placeholder="isOpen ? $t('SearchPlaceholderDefault') : placeholder"
            :class="[{'multiselect-is-invalid': state == false}, 'multiselect-component-single']"
            :disabled="disabled"
            :searchable="true"
            :options="options"
            @open="()=> {removeReadonly(); emitOpen(); isOpen = !isOpen; }"
            @close="()=> {emitClose(); isOpen = !isOpen;}"
            @input="()=> {emitData();}"
        >
            <template slot="singleLabel" slot-scope="props">
                {{ translate ? $t(props.option.text) : props.option.text }}
            </template>

            <template slot="option" slot-scope="props">
                <div class="option__desc d-flex justify-content-between" :class="{'footer-option': props.option.value == 'exit' && showFooter}">
                    <span>
                        {{ translate ? $t(`${props.option.text}`) : props.option.text }}
                    </span>

                    <span v-show="optionSelected && props.option.text == optionSelected.text">
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_4006_25886)">
                                <path d="M12 2C17.514 2 22 6.486 22 12C22 17.514 17.514 22 12 22C6.486 22 2 17.514 2 12C2 6.486 6.486 2 12 2ZM12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM18 16.538L13.408 11.99L17.954 7.403L16.538 6L11.993 10.589L7.405 6.046L6 7.451L10.593 12.003L6.046 16.595L7.451 18L12.006 13.404L16.597 17.954L18 16.538Z" fill="#4C4541"/>
                            </g>
                            <clipPath id="clip0_4006_25886">
                                <rect width="24" height="24" fill="white"/>
                            </clipPath>
                        </svg>
                    </span>
                </div>
            </template>

            <template slot="noResult" slot-scope="props">
                <span class="multiselect-warning">{{ $t('Operator.NoResultsFound') }}</span>
            </template>

            <template slot="noOptions" slot-scope="props">
                <span class="multiselect-warning">
                    {{ $t('EmptyList')}}
                </span>
            </template>
        </multiselect>

        <span class="multiselect-invalid-feedback" v-show="state == false">
            <slot></slot>
        </span>
    </div>
  </template>

  <script>
import Multiselect from 'vue-multiselect';

export default {
    components: {
      Multiselect,
    },
    props: {
        id: {
            type:String,
        },
        options: {
            type: Array,
            default: [],
        },
        optionSelected: {
            type: Object,
            default: null,
        },
        placeholder: {
            type: String,
            default: 'Select an option',
        },
        state: {
            type: Boolean,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        showFooter: {
            type: Boolean,
            default: false
        },
        translate:{
            type: Boolean,
            default: false
        }
    },
    methods: {
        removeReadonly(){
            let nodes = document.querySelectorAll('.multiselect__input');
            for (let elem of nodes) {
                elem.removeAttribute('readonly');
            }
        },
        emitOpen() {
            this.$emit('open');
        },
        emitClose() {
            this.$emit('close');
        },
        emitData(){
            this.$emit('input', this.optionSelected);
        }
    },
    data() {
        return {
            isOpen: false,
            selectedComponentOption: null
        }
    },
};
</script>

<style lang="scss">

.multiselect-component-single {
    min-height: 37.99px;
    height: 37.99px;

    &.multiselect--active {
        .multiselect__select {
            transform: none !important;
        }
    }

    .multiselect__content-wrapper {
        box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
        margin: 5px 0;
        border-radius: 6px;
        max-height: 300px !important;
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px !important;               /* width of the entire scrollbar */
            border-radius: 50%;
        }
        &::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: #FBEEE8;

        }
        &::-webkit-scrollbar-thumb {
            background-color: #CFC4BE;    /* color of the scroll thumb */
            border-radius: 20px;       /* roundness of the scroll thumb */
            border: 10px solid transparent
        }

        svg {
            margin-left: 10px;
        }

        .multiselect__content {
            margin: 0 !important;

            .multiselect-warning {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #6e6b7b;
            }
            .multiselect__element {
                span {
                    &::after {
                        content: "" !important;
                    }
                }

                .multiselect__option {
                    padding: 12px 21px;

                    color: #998F8A;

                    .option__desc {
                        white-space: normal;
                        display: flex;
                        align-items: center;
                        span{
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: #998F8A;
                        }
                    }

                    &:active {
                        background: #FFDBC4;

                        .option__desc {
                            span {
                                color: #974900;
                            }
                        }

                        &.multiselect__option--highlight {
                            background: #FFDBC4;
                        }
                    }

                    &.multiselect__option--highlight {
                        background: #FBEEE8;

                        .option__desc {
                            span {
                                color: #974900;
                            }
                        }
                    }

                    &.multiselect__option--selected {
                        background: #974900;

                        &:active {
                            .option__desc {
                                span {
                                    color: #974900;

                                    svg {
                                        path {
                                            fill: #974900 !important;
                                        }
                                    }
                                }
                            }
                        }

                        .option__desc {
                            span {
                                color: #fff;

                                svg {
                                    path {
                                        fill: #fff !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .multiselect__select {
        &::before {
            content: none !important;
        }

        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.47162 3.05602L5.00158 7.5909L0.528469 3.05593L1.17644 2.40774L4.73416 6.02906L5.00178 6.30146L5.26928 6.02894L8.82368 2.40785L9.47162 3.05602Z' fill='%23CFC4BE' stroke='%23CFC4BE' stroke-width='0.75'/%3E%3C/svg%3E");
        background-repeat: no-repeat !important;
        background-position: center !important;
    }

    &.multiselect--disabled {
        .multiselect__current, .multiselect__select {
            background: transparent;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.47162 3.05602L5.00158 7.5909L0.528469 3.05593L1.17644 2.40774L4.73416 6.02906L5.00178 6.30146L5.26928 6.02894L8.82368 2.40785L9.47162 3.05602Z' fill='%234C4541' stroke='%234C4541' stroke-width='0.75'/%3E%3C/svg%3E") !important;
        }

        .multiselect__tags {
            background: #F3F3F3;
        }
    }

    &.multiselect--active {
        .multiselect__tags {
            border-color: #974900 !important;
        }
    }

    .multiselect__tags {
        min-height: 37.99px;
        height: 37.99px;

        border-radius: 6px !important;
        border-color: #d8d6de;

        .multiselect__single,
        .multiselect__input {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #6e6b7b;
        }

        .multiselect__single {
            white-space: nowrap;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .multiselect__placeholder {
            font-weight: 400;
            font-size: 14px;
            line-height: 10px;
            color: #CFC4BE !important;
            margin-bottom: 0;
            padding-left: 5px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 100%;
        }
    }

    &.multiselect-is-invalid {
        border: 1px solid #D32F2F;
        border-radius: 6px;
        .multiselect__tags{
            border: none;
        }

        .multiselect__select {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.47162 3.05602L5.00158 7.5909L0.528469 3.05593L1.17644 2.40774L4.73416 6.02906L5.00178 6.30146L5.26928 6.02894L8.82368 2.40785L9.47162 3.05602Z' fill='%234C4541' stroke='%234C4541' stroke-width='0.75'/%3E%3C/svg%3E");
        }
    }

}

.multiselect-invalid-feedback {
    font-weight: 300;
    font-size: 10px;
    line-height: 14px;
    color: #D32F2F;
}

.multiselect__element:has(.footer-option) {
    border-top: 1px solid #CFC4BE !important;
    position: sticky;
    bottom: 0;
    background: #FFFFFF;
}
</style>
